<!-- 单条检测任务详情 -->
<template>
     <div class="userList">
        <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >受检管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back4">检测任务</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back3">任务详情</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back2">任务明细</el-breadcrumb-item>
            <el-breadcrumb-item>单条任务列表</el-breadcrumb-item>
        </el-breadcrumb>
       
        <div class="content">
          <!-- top_select -->
          <div class="selectbox">
                <div class="tple1" style="width:3.08rem">
                    <div>
                        <span class="seachText">受检人编号：</span>
                        <el-input class="serch_input" clearable placeholder="请输入编号/身份证号" v-model="searchInfo.cardId"  oninput ="value=value.replace(/[^0-9A-Za-z.]/g,'')"></el-input>
                    </div>
                    <div style="display:flex;align-items: center;margin-top: 0.19rem;">
                        <span class="seachText" style="margin-left:-0.11rem">脊柱评估结果：</span>
                        <el-select v-model="searchInfo.spineResult" class="serch_input">
                            <el-option
                                v-for="item in spineOtions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.label">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display:flex;align-items: center;margin-top: 0.19rem;">
                        <span class="seachText" style="margin-left:-0.11rem">躯干旋转角度：</span>
                        <el-select v-model="searchInfo.bodyResult" class="serch_input">
                            <el-option
                                v-for="item in bodyOtions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                   
                </div>
                <div class="tple1" style="width:3.08rem">
                    <div style="display:flex;align-items: center;">
                       <span class="seachText">姓名：</span>
                        <el-input class="serch_input" clearable placeholder="请输入姓名" v-model="searchInfo.name"></el-input>
                    </div>
                    <div style="display:flex;align-items: center;margin-top: 0.19rem;">
                        <span class="seachText" style="margin-left:-0.22rem">足部结果：</span>
                        <el-select v-model="searchInfo.footResult" class="serch_input">
                            <el-option
                                v-for="item in footOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.label">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display:flex;align-items: center;margin-top: 0.19rem;">
                        <span class="seachText" style="margin-left:-0.14rem">cobb角：</span>
                        <el-select v-model="searchInfo.cobb" class="serch_input">
                            <el-option
                                v-for="item in cobbOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="tple1" style="width:3.08rem">
                    <div>
                        <span class="seachText" style="margin-left:0.22rem">性别：</span>
                        <el-select v-model="searchInfo.gender" class="serch_input">
                            <el-option
                                v-for="item in sexOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                   <div style="display:flex;align-items: center;margin-top: 0.19rem;">
                        <span class="seachText" style="margin-left:-0.22rem">腰椎生理曲线：</span>
                        <el-select v-model="searchInfo.yaosui" class="serch_input">
                            <el-option
                                v-for="item in yaosuiOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="display:flex;align-items: center;margin-top: 0.19rem;">
                        <span class="seachText" style="margin-left:0rem">检测日期：</span>
                        <el-date-picker style="width: 2.0rem;" v-model="searchInfo.examinaTime" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" ></el-date-picker>
                    </div>
                </div>
                <div class="tple1" style="width:3.08rem">
                    <div>
                       <span class="seachText">手机号：</span>
                        <el-input class="serch_input" clearable placeholder="请输入手机号" v-model="searchInfo.mobile"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                    </div>
                     <div style="display:flex;align-items: center;margin-top: 0.19rem;">
                        <span class="seachText" style="margin-left:-0.33rem">胸椎生理曲线：</span>
                        <el-select v-model="searchInfo.xiongsui" class="serch_input">
                            <el-option
                                v-for="item in xiongsuiOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>  
                </div>
          </div>
          <!-- 查询框/重置框 -->
         <div class="seach">
             <div class="start" @click="startSeach">查询</div>
             <div class="reset" @click="clear">重置</div>
         </div>
            <!-- tableTop -->
            <div class="tableTop">
                <div class="tableTop_left">
                    <div class="downloadReport" v-if="!block" @click="downloadProReport"><i class="el-icon-tickets" style="margin-right:4px"></i>下载报告</div>
                    <div class="downloadReport_no" v-if="block"><i class="el-icon-tickets" style="margin-right:4px"></i>下载报告</div>
                    <div class="downloadOrgnData" v-if="!block" @click="downloadSpineOrgData"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载脊柱原始数据</div>
                    <div class="downloadOrgnData_no" v-if="block"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载脊柱原始数据</div>
                    <div class="downloadOrgnData" v-if="!block" @click="downloadFootOrgData"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载足部原始数据</div>
                    <div class="downloadOrgnData_no" v-if="block"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载足部原始数据</div>
                </div>
                <div class="tableTop_right">
                    <div class="search">
                    <el-input
                            placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            @change="search"
                            v-model="seachcon"
                            class="elSerch"
                            >
                            <template slot="append" style="background-color: #00CCCC;cursor pointer">搜索</template>
                    </el-input>
                    </div>
                    <div v-if="!block" class="delete" @click="removeSigle">删除</div>
                    <div v-if="block" class="deleteno">删除</div>
                </div>
            </div>
            <SitasT ref="st"/>
        </div>
        
     </div>
</template>
<script>
import SitasT from '@/components/table/sigleTaskTable.vue'
import { district } from '@/assets/js/cityCode.js'
import {getProReport,getSpineData,getFootData} from '@/api/checkTaskDetail.js'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
        SitasT,
    },
    data(){
        return{
            //冻结
            block:true,
           
            dwId:null,//下载id
            //搜索查询信息 
            searchInfo:{
                cardId:null,
                name:null,
                gender:null,
                mobile:null,
                cobb:null,
                yaosui:null,
                xiongsui:null,
                bodyResult:null,
                spineResult:null,
                footResult:null,
                examinaTime:"",
                },
            // 表头搜索框的任意搜索
             seachcon:null,
             //脊柱
             spineOtions:[ 
                        {
                            label:'全部',
                            value: 0,
                        },
                        {
                            label:'姿态异常',
                            value: 1,
                        },
                        {
                            label:'疑似脊柱侧弯',
                            value: 2,
                        },
                        {
                            label:'检测异常',
                            value: 3,
                        },
                        {
                            label:'正常',
                            value: 4,
                        }],
            //foot
            footOptions:[
                        {
                            label:'全部',
                            value: 0
                        },
                       {
                            label:'扁平足',
                            value: 1
                        }, {
                            label:'高弓足',
                            value: 2,
                        },
                        {
                            label:'先天畸形',
                            value: 3,
                        },
                        {
                            label:'其他足部异常',
                            value: 4,
                        },
                        {
                            label:'正常',
                            value: 5,
                        },
            ],
            //躯干
            bodyOtions:[
                {

                }
            ],
            //sex
            sexOptions: [ 
                        {        
                            label:'全部',
                            value: null
                        }, 
                        {
                            label:'男',
                            value: 0
                        }, 
                        {
                            label:'女',
                            value: 1,
                        }
                        ],
            cobbOptions:[],
            yaosuiOptions:[],
            xiongsuiOptions:[],
            // 地区 code 
            district,
        }
    },

    methods:{
        //面包屑事件
        back2(){
            this.$router.go(-1);
        },
        back3(){
            this.$router.go(-2);
        },
        back4(){
            this.$router.go(-3);
        },
        //顶部搜索
        startSeach(){
            this.$refs.st.getData(1,10,this.searchInfo.name,this.taskDetailId,this.searchInfo.spineResult,this.searchInfo.footResult,this.searchInfo.cardId,this.searchInfo.gender,this.searchInfo.mobile)
        },

        clear(){
            console.log("重置")
        },

        // 开始查询
        search(){
            this.$refs.st.getData(1,10,this.seachcon,this.$refs.st.taskDetailId,null,null,null);
        },
       //下载专业版报告
       downloadProReport(){
            getProReport({
                id:this.dwId
            }).then(res => {
                      if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                      
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        this.confirmLoading2 = false;
                        this.$ref.st.asa;
                        //this.$refs.multipleTable.clearSelection()
            }).catch((err) => {
                    if (err.response.data.type === 'application/json') {
                        const reader = new FileReader()
                        reader.readAsText(err.response.data)
                        reader.onload = e => {
                        const { message } = JSON.parse(reader.result)
                        this.$message.error(message)
                    }
                }
            });             
       },
       //下载脊柱原始数据  getSpineData
       downloadSpineOrgData(){
            getSpineData({
                id:this.dwId
            }).then(res => {
                      if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        this.confirmLoading2 = false;
                        this.$ref.st.$refs.multipleTable.clearSelection();
            }).catch((err) => {
                    if (err.response.data.type === 'application/json') {
                        const reader = new FileReader()
                        reader.readAsText(err.response.data)
                        reader.onload = e => {
                        const { message } = JSON.parse(reader.result)
                        this.$message.error(message)
                    }
                }
            });            
       },
       //下载足部原始数据
       downloadFootOrgData(){
       getFootData({
                id:this.dwId
            }).then(res => {
                      if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        this.confirmLoading2 = false;
            }).catch((err) => {
                    if (err.response.data.type === 'application/json') {
                        const reader = new FileReader()
                        reader.readAsText(err.response.data)
                        reader.onload = e => {
                        const { message } = JSON.parse(reader.result)
                        this.$message.error(message)
                    }
                }
            });            
       },
       //移除
       removeSigle(){
        this.$message.error("暂不支持！")
       }
    }
}
</script>
<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__icon {
    line-height: 0 !important;
}

/deep/.el-icon-date:before{
    line-height: 0.2rem !important;
    font-size:0.11rem
}

/deep/.el-input__inner{
    height: 32px !important;
    font-size: .11rem !important
  }
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
}
/deep/.el-button{
    padding: 0px 0 0 4px !important;
    text-align: center !important;
    color: #fff !important;
    font-size: 0.11rem !important;
  }
/deep/.el-date-editor .el-range-input{
    font-size: .11rem !important
}


// component style 
    //联合搜索框样式
.elSerch>>>.el-input__inner
    border: 1px solid #00CCCC !important
    width: 200px
    border-radius: 4px 0 0 4px

/*冻结样式 */  
.downloadReport_no
    width: 108px
    height: 32px
    background-color:#F4F4F4
    line-height: 32px
    font-size: 14px
    text-align: center
    color:#AEAEAE
    border-radius: 2px
    cursor not-allowed
.downloadOrgnData_no
    width: 142px
    height: 32px
    margin-left: 24px
    background-color:#F4F4F4
    line-height: 32px
    font-size: 14px
    text-align: center
    color: #AEAEAE
    border-radius: 2px
    cursor not-allowed

// 正常嵌套样式写法
.content
    overflow hidden
    position: relative
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
    /*newtop */
    .selectbox
        display: flex
        justify-content: space-between
        .tple1
            height: .69rem
            .seachText
                font-size: .11rem
                color: #AEAEAE
            .serch_input
                width:1.56rem !important 
    .seach
        width: 200px 
        height: 32px 
        position: absolute
        right: 120px
        top:180px
        display: flex
        .start
            width: 64px
            height: 32px
            background-color: #00cccc
            text-align: center
            line-height:32px
            color: #fff
            font-size: 14px
            margin-left: 16px
            cursor pointer
            border-radius: 4px
        .reset
            width: 64px
            height: 32px
            background-color: #ffffff
            text-align: center
            line-height:32px
            color: #858585
            border: 1px solid #858585
            font-size: 14px
            margin-left: 16px
            cursor pointer
            border-radius: 4px


    .tableTop 
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 140px
        .tableTop_left
            display: flex
            .downloadReport
                width: 108px
                height: 32px
                background-color: #00CCCC
                line-height: 32px
                font-size: 14px
                text-align: center
                color: #ffffff
                border-radius: 2px
                cursor pointer
            .downloadOrgnData
                width: 142px
                height: 32px
                margin-left: 24px
                background-color: #00CCCC
                line-height: 32px
                font-size: 14px
                text-align: center
                color: #ffffff
                border-radius: 2px
                cursor pointer
            .download_all
                width: 122px
                height: 32px
                margin-left: 24px
                background-color: #FFFFFF
                line-height: 32px
                border: 1px solid #00cccc
                font-size: 14px
                text-align: center
                color: #00CCCC
                border-radius: 2px
                cursor pointer
        .tableTop_right
            display: flex
            align-items: center
            .delete
                width: 64px
                height: 32px
                background-color: #FC5555
                text-align: center
                line-height:32px
                color: #fff
                font-size: 14px
                margin-left: 16px
                cursor pointer
                border-radius: 4px
            .deleteno
                width: 64px
                height: 32px
                background-color: #f6f6f6
                text-align: center
                line-height:32px
                color: #aeaeae
                font-size: 14px
                margin-left: 16px
                cursor pointer
                border-radius: 4px
</style>